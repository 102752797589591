import React from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

import { Container } from './styles';

import Layout from '../../components/Layout';
import Jumbotron from '../../components/Jumbotron';
import Input from '../../components/Input';
import Select from '../../components/Select';
import Textarea from '../../components/Textarea';
import Button from '../../components/Button';
import JumbotronSmaller from '../../components/Jumbotron-smaller';
import Message from '../../components/Message';

import { optionsSelect } from '../../content/pages/Contact';

import IcCheck from '../../assets/images/ic_check.svg';

import handleValidate from '../../helpers/validations/formValidations';
import templateEmail from '../../helpers/templates/email';

const FormPage: React.FC = () => {
  const recaptchaRef = React.useRef();
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [fone, setFone] = React.useState('');
  const [corban, setCorban] = React.useState('');
  const [select, setSelect] = React.useState('');
  const [textarea, setTextarea] = React.useState('');
  const [recaptcha, setRecaptcha] = React.useState('');
  const [validateFields, setValidateFields] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState(false);


  React.useEffect(() => {
    document.querySelector('body').scrollTo(0, 0)
  }, [])

  const handleSubmit = async e => {
    e.preventDefault();
    setValidateFields(true);

    if (!!name && !!email && !!fone && !!select && !!recaptcha) {

      const emailFormated = templateEmail({ name, email, fone, select, textarea })

      await axios.post("https://app-consig.zipdin.com.br/app-consig/services/email/send", emailFormated);

      setShowMessage(true);
      setValidateFields(false);

      setName('');
      setEmail('');
      setFone('');
      setSelect('');
      setTextarea('');

      return;
    }
  };

  return (
    <>
      <Layout>
        <Container>
          <section className="bg-header-contact"></section>
          <section className="container pos-relative">
            <Jumbotron
              className="jumbotron-contact"
              title="Atendimento ao Cliente"
              text="Informe o motivo do seu contato que rapidamente entraremos em contato."
            />
            <form className="form-contact" onSubmit={handleSubmit}>
              <div className="input-group">
                <div className="input-block">
                  <Input
                    name="name"
                    type="text"
                    label="Nome completo do responsável"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    error={validateFields && handleValidate(!name)}
                    errorMsg="Precisamos dessa informação."
                  />
                </div>
                <div className="input-block">
                  <Input
                    name="email"
                    type="email"
                    label="E-mail"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    error={validateFields && handleValidate(!email)}
                    errorMsg="Precisamos de um e-mail válido."
                  />
                </div>
              </div>
              <div className="input-group">
                <div className="input-block">
                  <Input
                    name="title"
                    type="text"
                    label="Título da mensagem"
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    error={validateFields && handleValidate(!title)}
                    errorMsg="Esta informação é muito importante para nós."
                  />
                </div>
                <div className="input-block">
                  <Input
                    name="fone"
                    type="tel"
                    label="Telefone com DDD"
                    value={fone}
                    onChange={e => setFone(e.target.value)}
                    error={validateFields && handleValidate(!fone)}
                    errorMsg="Precisamos de um telefone válido"
                  />
                </div>
              </div>
              <div className="input-group">
                <div className="input-block">
                  <Input
                    name="corban"
                    type="text"
                    label="Correspondente Bancário"
                    value={corban}
                    onChange={e => setCorban(e.target.value)}
                    error={validateFields && handleValidate(!corban)}
                    errorMsg="Esta informação é muito importante para nós."
                  />
                </div>
                <div className="input-block">
                  <Select
                    name="contact"
                    label="Motivo do contato"
                    error={validateFields && handleValidate(!select)}
                    errorMsg="Esta informação é muito importante para nós."
                    value={select}
                    onChange={e => setSelect(e.target.value)}
                    options={optionsSelect}
                  />
                </div>
              </div>
              <div className="input-block">
                <Textarea
                  name="textarea"
                  label="Mensagem"
                  value={textarea}
                  onChange={e => setTextarea(e.target.value)}
                />
              </div>
              <div className="form__recaptcha">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LcQqMQZAAAAAN79k2S50sBPmu_eyl0dSAhm0nHl"
                  onChange={e => setRecaptcha(e)}
                />
              </div>
              <div className="button-block">
                <Button type="submit">Enviar</Button>
              </div>
            </form>
          </section>
          <section className="section-cards-contact">
            <div className="container justifyment-row">
              <JumbotronSmaller
                className="card"
                title="Denúncia"
                text="Precisa falar sobre algo que vai contra as boas práticas dos negócios? Entre em contato para ajudar no combate à corrupção, fraudes, lavagem de dinheiro, discriminação e outras práticas criminosas."
              >
                <address>
                  <a
                    target="blank"
                    href="mailto:denuncia@zipdin.com.br"
                    className="content-card"
                  >
                    denuncia@zipdin.com.br
                  </a>
                </address>
              </JumbotronSmaller>
              <JumbotronSmaller
                className="card"
                title="Ouvidoria"
                text="Nossa Ouvidoria atua quando suas solicitações não são atendidas através do SAC. As reclamações são tratadas com total independência e imparcialidade e acompanhadas de perto até a sua efetiva conclusão."
              >
                <address>
                  <a
                    target="blank"
                    href="mailto:ouvidoria@zipdin.com.br"
                    className="content-card"
                  >
                    ouvidoria@zipdin.com.br
                  </a>{' '}
                  ou{' '}
                  <a
                    target="blank"
                    href="tel:08003331973"
                    className="content-card">
                    0800 333 1973
                  </a>
                </address>
              </JumbotronSmaller>
            </div>
          </section>
        </Container>
      </Layout>
      <Message
        onClick={() => setShowMessage(false)}
        state={showMessage}
        icon={IcCheck}
        text="Sua mensagem foi enviada com sucesso! Por favor, aguarde o nosso contato."
        buttonText="OK"
      />
    </>
  );
};

export default FormPage;
